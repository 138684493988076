<template>
  <transition name="fade">
    <div class="pop-up message">
      <div class="img" :class="message.status == 'ok' ? 'check' : 'error'"></div>
      <h4>{{message.message}}</h4>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
  .message{
    box-shadow: 4px 4px 10px rgba(142,182,227,.3), -4px -4px 10px rgba(195,219,246,.3);
    padding: 20px 10px;
    max-width: 250px;
  }
  .check{
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cpath style='fill:%2377DD00;' d='M256,0C115.3,0,0,115.3,0,256s115.3,256,256,256s256-115.3,256-256S396.7,0,256,0z'/%3E%3Cpath style='fill:%2366BB00;' d='M512,256c0,140.7-115.3,256-256,256V0C396.7,0,512,115.3,512,256z'/%3E%3Cpolygon style='fill:%23E7E7E7;' points='401.8,212.5 226,388.299 99.699,262.299 142.301,219.699 226,303.699 256,273.699 359.5,170.2 '/%3E%3Cpolygon style='fill:%23D3D3D8;' points='401.8,212.5 256,358.299 256,273.699 359.5,170.2 '/%3E%3C/svg%3E%0A");
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
  }
  .error{
    background-image: url("data:image/svg+xml,%0A%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cpath style='fill:%23FF3636;' d='M256,0C115.3,0,0,115.3,0,256s115.3,256,256,256s256-115.3,256-256S396.7,0,256,0z'/%3E%3Cpath style='fill:%23F40000;' d='M512,256c0,140.7-115.3,256-256,256V0C396.7,0,512,115.3,512,256z'/%3E%3Cpolygon style='fill:%23E7E7E7;' points='298.299,256 383.2,340.901 340.901,383.2 256,298.299 171.099,383.2 128.8,340.901 213.701,256 128.8,171.099 171.099,128.8 256,213.701 340.901,128.8 383.2,171.099 '/%3E%3Cpolygon style='fill:%23D3D3D8;' points='298.299,256 383.2,340.901 340.901,383.2 256,298.299 256,213.701 340.901,128.8 383.2,171.099 '/%3E%3C/svg%3E%0A");
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
  }
  .message h4{
    color: #0286e5;
    text-align: center;
    font-weight: 500;
  }
  
</style>



<script>
export default {
  props: ['message'],

  data:() => ({

  }),
  mounted() {
    var app = this
    setTimeout(function(){
      app.$emit('close')
    }, 1500)
  },
}
</script>