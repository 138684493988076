<template>
  <div>
    <div class="block-slide-toggle__btn">
      <!-- <button @click.prevent="fade = !fade" :class="fade ? 'active' : ''">{{name.name}} ({{lists.length}})</button> -->
      <button @click.prevent style="display: flex;justify-content: space-between;font-size: 18px;">
        <div :style="name.mode == 'requests' && modType == '2' ? 'color:#004d9d;font-weight: 700;' : ''">
          {{name.name}} <span v-if="lists.length !== 0">({{lists.length}})</span>
        </div>
        <div v-if="name.mode !== 'directions' && sum !== '0'" style="color: #004D9D;font-weight: 600;">{{sum}} {{$root.currencyName}}</div>
      </button>
    </div>
    <transition name="slide-fade" mode="out-in" >
      <div class="block-slide-toggle__content" v-if="fade">
        <ul class="block-list">
          <li 
            v-for="(list, index) in lists" 
            :key="index"
            :data-id="list.id"
            class="block-list__item"
            :class="name.mode"
          >
            <div @click.prevent="openList('update', index)">
              <!-- directions -->
              <div v-if="name.mode == 'directions'">
                <h5>{{list.poo_name}} - {{list.fd_name}}</h5>
                <p v-if="list.shipment_date_from && list.shipment_date_from !== '0000-00-00 00:00:00' && list.shipment_date_from !== '0000-00-00'">
                  {{list.shipment_date_from | date}}
                </p>
                <p v-else>-</p>
              </div>


              <!-- freight -->
              <h5 v-if="name.mode == 'freight'">{{list.service_name}}</h5>
              <p v-if="name.mode == 'freight'">{{list.sum}} {{list.currency_name == null ? '' : list.currency_name}}</p>


              <!-- kickbacks -->
              <h5 v-if="name.mode == 'kickbacks'">{{list.contPerson_str}}</h5>
              <p v-if="name.mode == 'kickbacks'">{{list.sum}} {{list.currency_name == null ? '' : list.currency_name}}, {{ + list.percent + '%'}}</p>


              <!-- requests -->
              <h5 v-if="name.mode == 'requests'">{{list.driver_name}}</h5>
              <p v-if="name.mode == 'requests'">{{list.customer_freight_sum}}</p>

            </div>
            <!-- arrow -->
            <a class="icon icon-arrow-right" @click.prevent="openDelete(index, list.id)"></a>
            <div class="block-list__btn">
              <a class="icon icon-copy block-list__btn__copy" @click.prevent="openList('copy', index)"></a>
              <a class="icon icon-delete block-list__btn__delete" @click.prevent="openList('delete', index)"></a>
            </div>
          </li>
        </ul>
        <a class="btn btn-transparent" v-if="canEdit" @click.prevent="openList('create', index)">Додати<div class="icon-plus"></div></a>
      </div>
    </transition>
    <InformationPlates 
      v-if="InformationPlates" 
      :hidden="canEdit"
      :application="application" 
      :list="{data, lists, name, mod, id: data.id, index, carrier, modType, modCreate, client_id: carrier ? carrier : data.client_id}" 
      @close="closeList"/>
  </div>
</template>



<style lang="scss" scoped>
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .block-slide-toggle__btn button::after{
    display: none;
  }
</style>


<script>
import InformationPlates from '@/components/agreements/InformationPlates'


export default {
  props: ['data', 'name', 'carrier', 'modType', 'modCreate', 'application', 'canEdit'],


  data:() => ({
    fade: true,
    lists: [],
    InformationPlates: false,
    mod: 'create',
    index: '',
    activeClass: false,
    sum: ''
  }),


  watch:{
    data: function(){
      this.lists = this.data[this.name.bd_name]
      this.sum = ''
      var app = this
      this.lists.forEach(function(item, index){

        if(item.currency_reversed !== '0'){
          if(app.name.mode == 'freight' || app.name.mode == 'kickbacks'){
            app.sum = +item.sum * item.exchange_rate + +app.sum
          }
          if(app.name.mode == 'requests'){
            app.sum = +item.customer_freight_sum + +app.sum
          }
        } else{
          if(app.name.mode == 'freight' || app.name.mode == 'kickbacks'){
            app.sum = +item.sum / item.exchange_rate + +app.sum
          }
          if(app.name.mode == 'requests'){
            app.sum = +item.customer_freight_sum + +app.sum
          }
        }

      })
      app.sum = (+app.sum).toFixed()
    }
  },


  created(){
    this.lists = this.data[this.name.bd_name]
    this.sum = ''
    var app = this
    this.lists.forEach(function(item, index){

      if(item.currency_reversed !== '0'){
        if(app.name.mode == 'freight' || app.name.mode == 'kickbacks'){
          app.sum = +item.sum * item.exchange_rate + +app.sum
        }
        if(app.name.mode == 'requests'){
          app.sum = +item.customer_freight_sum + +app.sum
        }
      } else{
        if(app.name.mode == 'freight' || app.name.mode == 'kickbacks'){
          app.sum = +item.sum / item.exchange_rate + +app.sum
        }
        if(app.name.mode == 'requests'){
          app.sum = +item.customer_freight_sum + +app.sum
        }
      }

    })
    app.sum = (+app.sum).toFixed()
  },



  methods: {
    openDelete(index, id){
      var options = document.querySelectorAll("li."+ this.name.mode); 
      options.forEach(function(item, i){
        if(i === index){
          item.classList.toggle('active')
        }
      });
    },

    
    openList(mod, index){
      this.mod = mod
      this.index = index
      this.InformationPlates = !this.InformationPlates
    },
    closeList(data){
      this.InformationPlates = !this.InformationPlates
      if(data){
        this.$emit('update')
      }
    }
  },



  components: {
    InformationPlates
  }
}
</script>